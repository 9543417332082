import "./Alert.scss";

import React, { PropsWithChildren } from "react";

interface Props {
  style?: React.CSSProperties | undefined;
  danger?: boolean;
}

export const Alert: React.FC<PropsWithChildren & Props> = ({
  children,
  danger,
  ...props
}) => {
  return (
    <div className={"alert" + (danger ? " --danger" : "")} {...props}>
      {children}
    </div>
  );
};
