import "./LogInPage.scss";

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { axios } from "../axios";

import { AuthLayout } from "../layout/AuthLayout";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { Head } from "../components/Head";

export function PasswordRecoveryPage() {
  const navigate = useNavigate();

  const { token } = useParams();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [loading, setLoading] = useState(false);

  return (
    <AuthLayout>
      <Head title="Password Recovery | Short-Route" />
      <form
        className="login-page"
        onSubmit={async (e) => {
          e.preventDefault();

          setErrors({});

          if (newPassword !== confirmPassword) {
            setErrors({
              confirmPassword: "Password is not the same.",
            });
            return;
          }

          setLoading(true);
          try {
            await axios.post("/api/user/password-recovery", {
              token,
              newPassword,
            });

            navigate("/?message=Try to log in with new password.");
          } catch (e: any) {
            setErrors(e.response.data);
          } finally {
            setLoading(false);
          }
        }}
      >
        <h1 className="auth-tiitle">Password Recovery</h1>
        <p className="auth-slug">
          Remember to create strong, unique passwords for each of your accounts
          and consider using a password manager to securely store them in the
          future.
        </p>
        <Input
          style={{ marginTop: 40 }}
          type="password"
          title="New Password:"
          placeholder="Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          error={errors.newPassword || errors.global}
        />

        <Input
          style={{ marginTop: 10 }}
          type="password"
          title="Verify Password"
          placeholder="Verify Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={errors.confirmPassword}
        />

        <Button
          type="submit"
          block
          loading={loading}
          style={{ flex: 1, marginTop: 20 }}
        >
          Submit
        </Button>
      </form>
    </AuthLayout>
  );
}
