import "./ShortenLinkPage.scss";

import React, { useState } from "react";
import moment from "moment";

import { Panel } from "../components/Panel";
import { Input } from "../components/Input";
import { Checkbox } from "../components/Checkbox";
import { Value, WithCalendar } from "../components/WithCalendar";
import { Button } from "../components/Button";
import { axios } from "../axios";
import { useNavigate } from "react-router-dom";
import { Head } from "../components/Head";

export const ShortenLinkPage: React.FC = () => {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({} as { [key: string]: string });

  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");

  const [secured, setSecured] = useState(false);
  const [password, setPassword] = useState("");

  const [utm, setUtm] = useState(false);
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmContent, setUtmContent] = useState("");
  const [utmTerm, setUtmTerm] = useState("");

  const [expired, setExpired] = useState(false);
  const [expirationAt, setExpirationAt] = useState(null as Value);

  return (
    <>
      <Head title="Shorten Link | Short-Route" />

      <h1 className="page-title">Shorten New Link</h1>

      <Panel>
        <h2 className="panel-title">Type your data here </h2>

        <form
          onSubmit={async (e) => {
            e.preventDefault();

            setErrors({});
            try {
              await axios.post("/api/link", {
                title,
                link,
                expired,
                expirationAt: expired ? expirationAt : undefined,
                secured,
                password: secured ? password : undefined,

                utm,
                utmSource: utm ? utmSource : undefined,
                utmMedium: utm ? utmMedium : undefined,
                utmCampaign: utm ? utmCampaign : undefined,
                utmContent: utm ? utmContent : undefined,
                utmTerm: utm ? utmTerm : undefined,
              });

              navigate("/r/your-links");
            } catch (e: any) {
              const errors = e.response.data as { [key: string]: string };
              if (errors.global === "Choose expiration date or disable it.") {
                errors.expirationAt = errors.global;
              }

              if (errors.global === "Password should be between 8-32 length.") {
                errors.password = errors.global;
              }

              setErrors(errors);
            }
          }}
        >
          <Input
            title="Title:"
            placeholder="Link for marketing"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={errors.title}
          />

          <Input
            title="Your Link:"
            placeholder="Your Link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            error={errors.link}
            style={{marginTop: 10}}
          />

          <Checkbox
            id="expiration"
            text="Add expiration?"
            style={{ marginTop: 30 }}
            checked={expired}
            onChange={(e) => setExpired(e.target.checked)}
          />

          <p>The link will become disabled once the expiration date passes.</p>

          {expired && (
            <WithCalendar
              onChange={(val) => setExpirationAt(val)}
              value={expirationAt}
            >
              <Input
                style={{ marginTop: 10 }}
                title="Date:"
                placeholder="Click to open a calendar"
                value={
                  expirationAt
                    ? moment(expirationAt as Date).format("MM.DD.YYYY")
                    : ""
                }
                error={errors.expirationAt}
              />
            </WithCalendar>
          )}

          <Checkbox
            id="protection"
            text="Enable Password Protection?"
            style={{ marginTop: 30 }}
            checked={secured}
            onChange={(e) => setSecured(e.target.checked)}
          />
          <p>
            Password protection provides a way to secure your link, ensuring
            that only individuals with the correct passwords can gain access to
            it.
          </p>

          {secured && (
            <Input
              style={{ width: 500, marginTop: 10 }}
              title="Password:"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errors.password}
            />
          )}

          <Checkbox
            id="utm"
            text="Add UTM?"
            style={{ marginTop: 30 }}
            checked={utm}
            onChange={(e) => setUtm(e.target.checked)}
          />

          <p>
            A UTM (Urchin Tracking Module) code is a snippet of text added to
            the end of a URL to track the metrics and performance of a specific
            digital marketing campaign.
          </p>

          {utm && (
            <>
              <Input
                style={{ marginTop: 10 }}
                title="Source:"
                value={utmSource}
                onChange={(e) => setUtmSource(e.target.value)}
                error={errors.utmSource}
              />
              <Input
                style={{ marginTop: 10 }}
                title="Medium:"
                value={utmMedium}
                onChange={(e) => setUtmMedium(e.target.value)}
                error={errors.utmMedium}
              />
              <Input
                style={{ marginTop: 10 }}
                title="Campaign:"
                value={utmCampaign}
                onChange={(e) => setUtmCampaign(e.target.value)}
                error={errors.utmCampaign}
              />
              <Input
                style={{ marginTop: 10 }}
                title="Content:"
                value={utmContent}
                onChange={(e) => setUtmContent(e.target.value)}
                error={errors.utmContent}
              />
              <Input
                style={{ marginTop: 10 }}
                title="Term:"
                value={utmTerm}
                onChange={(e) => setUtmTerm(e.target.value)}
                error={errors.utmTerm}
              />
            </>
          )}

          <Button type="submit" style={{ marginTop: 30 }}>
            Shorten
          </Button>
        </form>
      </Panel>
    </>
  );
};
