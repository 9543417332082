import "./Dashboard.scss";

import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";

import { Pagination } from "../types/Pagination";
import { Link } from "../types/Link";

import { axios } from "../axios";

import { ShortLink } from "../components/ShortLink";
import { Panel } from "../components/Panel";
import { Button } from "../components/Button";
import { Empty } from "../components/Empty";
import { PageLoading } from "../components/PageLoading";
import { Head } from "../components/Head";

export function HiddenLinksPage() {
  const [firstLoading, setFirstLoading] = useState(true);

  const [links, setLinks] = useState([] as Link[]);
  const [page, setPage] = useState(0);
  const [end, setEnd] = useState(false);
  const [loading, setLoading] = useState(true);

  async function loadMore() {
    setLoading(true);
    try {
      const resp: AxiosResponse<Pagination<Link>> = await axios.get(
        "/api/link",
        {
          params: {
            sort: "id,desc",
            size: 20,
            page: page + 1,
            hidden: true,
          },
        }
      );

      if (page + 1 === resp.data.totalPages - 1) {
        setEnd(true);
      }

      setPage(page + 1);
      setLinks([...links, ...resp.data.content]);
    } finally {
      setLoading(false);
    }
  }

  async function loadLinks() {
    setLoading(true);
    try {
      const resp: AxiosResponse<Pagination<Link>> = await axios.get(
        "/api/link",
        {
          params: {
            sort: "id,desc",
            size: 20,
            page: 0,
            hidden: true,
          },
        }
      );

      if (resp.data.totalPages === 1) {
        setEnd(true);
      }

      setPage(0);
      setLinks(resp.data.content);
    } finally {
      setFirstLoading(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadLinks();
  }, []);

  return (
    <>
      <Head title="Hidden Links | Short-Route" />
      {firstLoading && <PageLoading />}
      {!firstLoading && (
        <>
          <h1 className="page-title">Hidden Links</h1>

          {links.length === 0 && !loading && <Empty />}

          {links.map((link) => {
            return (
              <Panel
                key={link.id}
                style={{ marginBottom: 20, paddingBottom: 0 }}
              >
                <ShortLink onUpdate={() => loadLinks()} link={link} />
              </Panel>
            );
          })}

          {!end && links.length !== 0 && (
            <Button loading={loading} onClick={() => loadMore()}>
              Load More
            </Button>
          )}
        </>
      )}
    </>
  );
}
