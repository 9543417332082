import "./Empty.scss";

import React, { HtmlHTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "./Button";

interface Props {
  withPadding?: boolean;
}

export const Empty: React.FC<HtmlHTMLAttributes<HTMLDivElement> & Props> = ({
  withPadding,
  ...props
}) => {

  const navigate = useNavigate();

  return (
    <div
      className={"empty" + (withPadding ? " --with-padding" : "")}
      {...props}
    >
      <img src="/img/empty-image.svg" alt="Empty" />
      <p>There are no records to display.</p>

      <Button onClick={() => navigate("/r/shorten-new-link")}>Shorten Link</Button>
    </div>
  );
};
