import "./InnerLayout.scss";

import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";

import { Navigation } from "../components/Navigation";
import { AuthContext } from "../context/AuthUserContext";
import { Alert } from "../components/Alert";
import { axios } from "../axios";
import { InfoModal } from "../modal/InfoModal";

export const InnerLayout: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <div className="inner-layout">
      <Navigation />

      <div className="content">
        {!user?.confirmedEmail && (
          <Alert style={{ marginBottom: 40, maxWidth: 900 }} danger>
            To access the service, kindly verify your email address.{" "}
            <a
              href="/"
              onClick={async (e) => {
                e.preventDefault();
                await axios.post("/api/user/send-confirmation-email");
                setModalVisible(true);
              }}
            >
              Send email again.
            </a>
          </Alert>
        )}

        <Outlet />

        <InfoModal
          title="Info"
          open={modalVisible}
          onHide={() => setModalVisible(false)}
          description="We sent the email to you. Please, confirm your email."
        />
      </div>
    </div>
  );
};
