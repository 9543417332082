import "./Button.scss";
import React from "react";
import { Spinner } from "./Spinner";

interface Props {
  block?: boolean;
  loading?: boolean;
}

export const Button: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & Props
> = ({ children, block, loading, ...props }) => {
  return (
    <button className={"button" + (block ? " block" : "")} {...props}>
      {children}
      {loading && <Spinner className="--for-button" />}
    </button>
  );
};
