import "./QRModal.scss";

import React from "react";

import { Modal } from "../components/Modal";
import { Button } from "../components/Button";

interface Props {
  open: boolean;
  onHide: () => void;
  data: string;
}

export const QRModal: React.FC<Props> = ({ onHide, open, data }) => {
  const link = `${process.env.REACT_APP_API_URL}/api/qr?data=${data}`;
  return (
    <Modal open={open} onHide={onHide}>
      <div className="qr-modal">
        <h2 className="modal__title">QR</h2>
        <p className="modal__description">
          "QR" commonly refers to Quick Response codes. These are
          two-dimensional barcodes that can be scanned using a smartphone or a
          dedicated QR code reader.
        </p>
        <div className="qr-container" style={{ marginBottom: 30 }}>
          <img className="qr-image" src={link} alt="qr"/>
        </div>
        <Button
          block
          onClick={() => {
            window.location.href = link;
          }}
        >
          Download
        </Button>
        <Button block style={{ marginTop: 10 }} onClick={onHide}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
