import "./Navigation.scss";

import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/AuthUserContext";

const NLink: React.FC<{
  title: string;
  link: string;
  img: string;
  main?: boolean;
  style?: React.CSSProperties | undefined;
  onClick?: React.MouseEventHandler;
}> = ({ title, link, img, style, main, onClick }) => {
  return (
    <Link
      className={
        "nav-link" + (window.location.pathname === link ? " active" : "")
        + (main ? " main" : "")
      }
      to={link}
      style={style}
      onClick={(e) => {
        const element = document.querySelector(".inner-layout .content");
        element?.scrollTo(0, 0);

        onClick && onClick(e);
      }}
    >
      <img className="nav-link__img" src={img} alt="link icon" />

      {main && <b>{title}</b>}
      {!main && title}
    </Link>
  );
};

export const Navigation: React.FC = () => {
  const { setUser } = useContext(AuthContext);

  return (
    <nav className="navigation">
      <b className="title">Short Route</b>

      <NLink
        title="Dashboard"
        link="/r/dashboard"
        img="/img/dashboard-icon.svg"
        style={{ marginTop: 40 }}
      />

      <span className="navigation__section-title">Link Managment</span>

      <NLink
        title="Shorten Link"
        link="/r/shorten-new-link"
        img="/img/create-new-link-icon.svg"
        main
      />

      <NLink
        title="Your Links"
        link="/r/your-links"
        img="/img/links-icon.svg"
      />

      <NLink
        title="Hidden Links"
        link="/r/hidden-links"
        img="/img/hidden-links-icon.svg"
      />

      {/* <span className="navigation__section-title">API</span>

      <NLink
        title="API Documentation"
        link="/"
        img="/img/api-documentation-icon.svg"
      />

      <NLink title="API Key" link="/" img="/img/api-key-icon.svg" /> */}

      <span className="navigation__section-title">Account</span>

      <NLink
        title="Account"
        link="/r/account-settings"
        img="/img/edit-account-icon.svg"
      />

      <NLink
        title="Exit"
        link="/"
        img="/img/exit-icon.svg"
        onClick={() => {
          setUser(null);
          localStorage.removeItem("token");
        }}
      />
    </nav>
  );
};
