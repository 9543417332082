import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {axios} from "../axios";


export const ConfirmationPage: React.FC = () => {

    const { userId, token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios.post(`/api/user/${userId}/confirm?token=${token}`)
            .then(() => {
                navigate("/?message=Your email was confirmed.");
            })
            .catch(() => {
                navigate("/?message=Token invalid. We cannot confirm your email.&error=true")
            })

        
    }, [userId, token, navigate]);
    
    return null;
};