import "./Modal.scss";

import React, { PropsWithChildren, useEffect } from "react";

export interface ModalProps {
  onHide: () => void;
  open: boolean;
}

export const Modal: React.FC<PropsWithChildren & ModalProps> = ({
  open,
  children,
  onHide,
}) => {
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        onHide();
      }
    };

    window.addEventListener("keyup", listener);

    return () => {
      window.removeEventListener("keyup", listener);
    };
  }, [onHide]);

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  if (!open) return null;

  return (
    <div className={"modal" + (open ? " open" : "")}>
      <div className="modal__window">{children}</div>
    </div>
  );
};
