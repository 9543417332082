import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Outlet, useNavigate } from "react-router-dom";

import { axios } from "../../axios";

import { User } from "../../types/User";

import { AuthContext } from "../../context/AuthUserContext";

export const UserHolder: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null as User | null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get("/api/user/self")
        .then((resp: AxiosResponse<User>) => {
          setUser(resp.data);
          setLoading(false);
          if (window.location.pathname === "/") {
            navigate("/r/dashboard");
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [navigate]);

  if (loading) return null;

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser: setUser,
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  );
};
