import { useEffect } from "react";

export const Analytics = () => {

  function init() {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // @ts-ignore
      window.dataLayer.push(arguments);
    }
    // @ts-ignore
    gtag("js", new Date());
    // @ts-ignore
    gtag("config", "G-T50WE8EZJJ");
  }

  useEffect(() => {

    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') {
        return;
    }

    init();

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute(
      "src",
      "https://www.googletagmanager.com/gtag/js?id=G-T50WE8EZJJ"
    );

    document.head.appendChild(script);
  }, []);

  return null;
};
