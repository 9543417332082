import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthUserContext";

export const UserSecurity: React.FC = () => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);

  if (!user) return null;

  return <Outlet />;
};
