import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { UserHolder } from "./security/UserHolder";

import { Analytics } from "./Analytics";
import { InnerLayout } from "../layout/InnerLayout";

import { LogInPage } from "../pages/LogInPage";
import { DashboardPage } from "../pages/DashboardPage";
import { RegistrationPage } from "../pages/RegistrationPage";
import { ForgotPasswordPage } from "../pages/ForgotPasswordPage";
import { PasswordRecoveryPage } from "../pages/PasswordRecoveryPage";
import { EditAccountPage } from "../pages/EditAccountPage";
import { ShortenLinkPage } from "../pages/ShortenLinkPage";
import { YourLinksPage } from "../pages/YourLinksPage";
import { HiddenLinksPage } from "../pages/HiddenLinksPage";
import { UserSecurity } from "./security/UserSecurity";
import { ConfirmationPage } from "../pages/ConfirmationPage";


const router = createBrowserRouter([
  {
    element: <UserHolder />,
    children: [
      {
        path: "/",
        element: <LogInPage />,
      },
      {
        path: "/sign-up",
        element: <RegistrationPage />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/password-recovery/:token",
        element: <PasswordRecoveryPage />,
      },
      {
        path: "/r",
        element: <UserSecurity />,
        children: [
          {
            path: "/r",
            element: <InnerLayout />,
            children: [
              {
                path: "/r/dashboard",
                element: <DashboardPage />,
              },
              {
                path: "/r/account-settings",
                element: <EditAccountPage />,
              },
              {
                path: "/r/shorten-new-link",
                element: <ShortenLinkPage />,
              },
              {
                path: "/r/your-links",
                element: <YourLinksPage />,
              },
              {
                path: "/r/hidden-links",
                element: <HiddenLinksPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/confirm/:userId/:token",
    element: <ConfirmationPage />,
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <Analytics />
    </>
  );
}

export default App;
