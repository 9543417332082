import "./LogInPage.scss";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { axios } from "../axios";

import { AuthLayout } from "../layout/AuthLayout";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { Head } from "../components/Head";

export function ForgotPasswordPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [loading, setLoading] = useState(false);

  return (
    <AuthLayout>
      <Head title="Forgot Password | Short-Route" />
      <form
        className="login-page"
        onSubmit={async (e) => {
          e.preventDefault();

          setErrors({});

          setLoading(true);
          try {
            await axios.post("/api/user/forgot-password", {
              email,
            });

            navigate("/?message=The link with instruction was sent to you.");
          } catch (e: any) {
            setErrors(e.response.data);
          } finally {
            setLoading(false);
          }
        }}
      >
        <h1 className="auth-tiitle">Forgot Password</h1>

        <p className="auth-slug">
          Remember to create strong, unique passwords for each of your accounts
          and consider using a password manager to securely store them in the
          future.
        </p>

        <Input
          style={{ marginTop: 40 }}
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email || errors.global}
        />

        <Button
          type="submit"
          block
          loading={loading}
          style={{ flex: 1, marginTop: 20 }}
        >
          Submit
        </Button>
      </form>
    </AuthLayout>
  );
}
