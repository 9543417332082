import React, { useState } from "react";

import { axios } from "../axios";

import { Link } from "../types/Link";
import { ModalProps } from "../components/Modal";

import { Modal } from "../components/Modal";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { Checkbox } from "../components/Checkbox";

interface Props extends ModalProps {
  link: Link;
  onUpdate: () => void;
}

export const SetUTMModal: React.FC<Props> = ({
  open,
  link,
  onHide,
  onUpdate,
}) => {
  const [utm, setUtm] = useState(link.utm);
  const [utmSource, setUtmSource] = useState(link.utmSource);
  const [utmMedium, setUtmMedium] = useState(link.utmMedium);
  const [utmCampaign, setUtmCampaign] = useState(link.utmCampaign);
  const [utmContent, setUtmContent] = useState(link.utmContent);
  const [utmTerm, setUtmTerm] = useState(link.utmTerm);

  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [loading, setLoading] = useState(false);

  return (
    <Modal open={open} onHide={onHide}>
      <h2 className="modal__title">UTM</h2>
      <p className="modal__description">
        A UTM (Urchin Tracking Module) code is a snippet of text added to the
        end of a URL to track the metrics and performance of a specific digital
        marketing campaign.
      </p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setErrors({});
          setLoading(true);
          try {
            await axios.patch(`/api/link/${link.id}/set-utm`, {
              utm,
              utmSource,
              utmMedium,
              utmCampaign,
              utmContent,
              utmTerm,
            });
            onUpdate();
          } catch (e: any) {
            setErrors(e.response.data);
          } finally {
            setLoading(false);
          }
        }}
      >
        <Checkbox
          id="utm"
          type="checkbox"
          text="Utm enabled?"
          onClick={() => setUtm(!utm)}
          checked={utm}
        />

        <Input
          style={{ marginTop: 10 }}
          title="Source:"
          value={utmSource}
          onChange={(e) => setUtmSource(e.target.value)}
          error={errors.utmSource}
        />
        <Input
          style={{ marginTop: 10 }}
          title="Medium:"
          value={utmMedium}
          onChange={(e) => setUtmMedium(e.target.value)}
          error={errors.utmMedium}
        />
        <Input
          style={{ marginTop: 10 }}
          title="Campaign:"
          value={utmCampaign}
          onChange={(e) => setUtmCampaign(e.target.value)}
          error={errors.utmCampaign}
        />
        <Input
          style={{ marginTop: 10 }}
          title="Content:"
          value={utmContent}
          onChange={(e) => setUtmContent(e.target.value)}
          error={errors.utmContent}
        />
        <Input
          style={{ marginTop: 10 }}
          title="Term:"
          value={utmTerm}
          onChange={(e) => setUtmTerm(e.target.value)}
          error={errors.utmTerm}
        />
        <Button type="submit" loading={loading} block style={{ marginTop: 20 }}>
          Save
        </Button>
        <Button
          onClick={onHide}
          style={{ marginTop: 10 }}
          block
        >
          Cancel
        </Button>
      </form>
    </Modal>
  );
};
