import "./Panel.scss";
import React, { HtmlHTMLAttributes } from "react";

export const Panel: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return (
    <div className="panel" {...props}>
      {children}
    </div>
  );
};
