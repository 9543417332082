import "react-calendar/dist/Calendar.css";
import "./SetExpirationModal.scss";

import React, { useState } from "react";
import Calendar from "react-calendar";

import { axios } from "../axios";

import { Link } from "../types/Link";
import { ModalProps } from "../components/Modal";

import { Modal } from "../components/Modal";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import moment from "moment";
import { Checkbox } from "../components/Checkbox";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface Props extends ModalProps {
  link: Link;
  onUpdate: () => void;
}

export const SetExpirationModal: React.FC<Props> = ({
  open,
  link,
  onHide,
  onUpdate,
}) => {
  const [expired, setExpired] = useState(link.expired);
  const [expirationAt, setExpirationAt] = useState(() => {
    if (!link.expirationAt) {
      return null;
    }

    return moment.utc(link.expirationAt).toDate() as Value;
  });
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [loading, setLoading] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  return (
    <Modal open={open} onHide={onHide}>
      <h2 className="modal__title">Expiration</h2>
      <p className="modal__description">
        The link will be disabled once it reaches its expiration date.
      </p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setErrors({});

          var time = moment(expirationAt as Date);

          try {
            await axios.patch(`/api/link/${link.id}/set-expiration`, {
              expired,
              expirationAt: time.format("YYYY-MM-DD") + "T00:00:00Z",
            });
            onUpdate();
          } catch (e: any) {
            setErrors(e.response.data);
          } finally {
            setLoading(false);
          }
        }}
      >
        <Checkbox
          id="expired"
          type="checkbox"
          text="Expired?"
          onClick={() => setExpired(!expired)}
          checked={expired}
        />
        <Input
          style={{ marginTop: 10 }}
          title="Expiration At:"
          value={
            expirationAt
              ? moment(expirationAt as Date).format("MM.DD.YYYY")
              : ""
          }
          onClick={() => setCalendarOpen(!calendarOpen)}
          error={errors.global || errors.password}
        />

        {calendarOpen && (
          <Calendar
            className="calendar"
            onChange={(val) => {
              setExpirationAt(val);
              setCalendarOpen(false);
            }}
            value={expirationAt}
          />
        )}

        <Button type="submit" style={{ marginTop: 20 }} block loading={loading}>
          Save
        </Button>
        <Button
          onClick={onHide}
          style={{ marginTop: 10 }}
          block
        >
          Cancel
        </Button>
      </form>
    </Modal>
  );
};
