import "./ShortLink.scss";

import React, { useState } from "react";
import moment from "moment";

import { Link } from "../types/Link";

import { SetPasswordModal } from "../modal/SetPasswordModal";
import { SetUTMModal } from "../modal/SetUTMModal";
import { SetExpirationModal } from "../modal/SetExpirationModal";
import { ConfirmationModal } from "../modal/ConfirmationModal";
import { axios } from "../axios";
import { Stat } from "./shortLink/Stat";
import { formatNumber } from "../utils/formatNumber";
import { InfoModal } from "../modal/InfoModal";
import { QRModal } from "../modal/QRModal";

export const ShortLink: React.FC<{
  link: Link;
  onUpdate: () => void;
}> = ({ link, onUpdate }) => {
  const [modal, setModal] = useState(
    null as null | "password" | "utm" | "expiration" | "remove" | "info" | "qr"
  );

  const shortLink = `https://${link.domain}/${link.shortCode}`;

  return (
    <>
      <div className="short-link">
        {
          link.title &&
          <div className="short-link__title">
            <b>Title:</b> {link.title}</div>
        }
        <div className="short-link__header">
          <div className="short-link__link-field">
            <span className="short-link__link-field__title">Your link:</span>
            <span className="short-link__link-field__value">
              <a href={link.link}>{link.link}</a>
            </span>
          </div>
          <div className="short-link__link-field">
            <span className="short-link__link-field__title">Short Link:</span>
            <span className="short-link__link-field__value">
              <a
                href={shortLink}
                onClick={(e) => {
                  e.preventDefault();
                  setModal("info");
                  navigator.clipboard.writeText(shortLink);
                }}
              >
                {shortLink}
              </a>
            </span>
          </div>
        </div>

        <div className="short-link__stat">
          <Stat
            title="Redirects:"
            value={formatNumber(link.countOfRedirects)}
          />
          <Stat
            title="Last At:"
            value={
              link.lastRedirectTime
                ? moment.utc(link.lastRedirectTime).format("MM.DD.YYYY")
                : "None"
            }
          />
          <Stat title="UTM:" green={link.utm} value={link.utm ? "Yes" : "No"} />
          <Stat
            title="Secure:"
            green={link.secured}
            value={link.secured ? "Yes" : "No"}
          />
          <Stat
            title="Expiration:"
            green={link.expired}
            value={
              link.expirationAt
                ? moment.utc(link.expirationAt).format("MM.DD.YYYY")
                : "None"
            }
          />
        </div>

        <div className="short-link__operation">
          <span
            className="short-link__operation__item"
            onClick={() => setModal("password")}
          >
            Set Password
          </span>
          <span className="short-link__operation__divider"></span>
          <span
            className="short-link__operation__item"
            onClick={() => setModal("utm")}
          >
            Set UTM
          </span>
          <span className="short-link__operation__divider"></span>
          <span
            className="short-link__operation__item"
            onClick={() => setModal("expiration")}
          >
            Set Expiration
          </span>
          <span className="short-link__operation__divider"></span>
          <span
            className="short-link__operation__item"
            onClick={() => setModal("qr")}
          >
            QR
          </span>
          <span className="short-link__operation__divider"></span>

          {!link.disabled && (
            <span
              className="short-link__operation__item"
              onClick={() => setModal("remove")}
            >
              Remove
            </span>
          )}

          {link.disabled && (
            <span
              className="short-link__operation__item"
              onClick={async () => {
                await axios.patch(`/api/link/${link.id}/activate`);
                onUpdate && onUpdate();
              }}
            >
              Activate
            </span>
          )}

          <span className="short-link__operation__divider"></span>
        </div>
      </div>

      <SetPasswordModal
        open={modal === "password"}
        link={link}
        onUpdate={() => {
          onUpdate();
          setModal(null);
        }}
        onHide={() => {
          setModal(null);
        }}
      />
      <SetUTMModal
        open={modal === "utm"}
        link={link}
        onUpdate={() => {
          onUpdate();
          setModal(null);
        }}
        onHide={() => {
          setModal(null);
        }}
      />
      <SetExpirationModal
        open={modal === "expiration"}
        link={link}
        onUpdate={() => {
          setModal(null);
          onUpdate();
        }}
        onHide={() => {
          setModal(null);
        }}
      />
      <ConfirmationModal
        title="Removing"
        description="After deleting the link, you can find it in the hidden links section."
        onYes={async () => {
          axios.delete(`/api/link/${link.id}`).then(() => {
            setModal(null);
            onUpdate();
          });
        }}
        open={modal === "remove"}
        onHide={() => setModal(null)}
      />

      <InfoModal
        title="Info"
        description="The short link was copied to your clipboard."
        open={modal === "info"}
        onHide={() => setModal(null)}
      />
      <QRModal
        open={modal === "qr"}
        data={shortLink}
        onHide={() => setModal(null)}
      />
    </>
  );
};
