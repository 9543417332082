import "./PageLoading.scss";

import React from "react";
import { Spinner } from "./Spinner";

export const PageLoading: React.FC = () => {
  
    return (
        <div className="page-loading">
            <Spinner />
        </div>
    );
};