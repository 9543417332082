import React, { useState } from "react";

import { axios } from "../axios";

import { Link } from "../types/Link";
import { ModalProps } from "../components/Modal";

import { Modal } from "../components/Modal";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { Checkbox } from "../components/Checkbox";

interface Props extends ModalProps {
  link: Link;
  onUpdate: () => void;
}

export const SetPasswordModal: React.FC<Props> = ({
  open,
  link,
  onHide,
  onUpdate,
}) => {
  const [secured, setSecured] = useState(link.secured);
  const [password, setPassword] = useState(link.password);
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [loading, setLoading] = useState(false);

  return (
    <Modal open={open} onHide={onHide}>
      <h2 className="modal__title">Password Protection</h2>
      <p className="modal__description">
        Password protection provides a way to secure your link, ensuring that
        only individuals with the correct passwords can gain access to it.
      </p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setErrors({});
          setLoading(true);

          try {
            await axios.patch(`/api/link/${link.id}/set-password`, {
              secured,
              password: secured ? password : undefined,
            });
            onUpdate();
          } catch (e: any) {
            setErrors(e.response.data);
          } finally {
            setLoading(false);
          }
        }}
      >
        <Checkbox
          id="secured"
          type="checkbox"
          text="Secured?"
          onClick={() => setSecured(!secured)}
          checked={secured}
        />

        <Input
          value={password}
          title="Password:"
          style={{ marginTop: 20 }}
          onChange={(e) => {
            if (e.target.value !== "") {
              setSecured(true);
            }
            setPassword(e.target.value);
          }}
          error={errors.global || errors.password}
        />
        <Button type="submit" block loading={loading} style={{ marginTop: 20 }}>
          Save
        </Button>

        <Button onClick={onHide} style={{ marginTop: 10 }} block>
          Cancel
        </Button>
      </form>
    </Modal>
  );
};
