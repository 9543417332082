import "./LogInPage.scss";

import { useContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { axios } from "../axios";

import { User } from "../types/User";

import { AuthContext } from "../context/AuthUserContext";

import { AuthLayout } from "../layout/AuthLayout";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { Alert } from "../components/Alert";
import { Head } from "../components/Head";

export function LogInPage() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [loading, setLoading] = useState(false);

  const { setUser } = useContext(AuthContext);

  useEffect(() => {
    if (searchParams.has("token")) {

       // @ts-ignore
       if (window.isNativeApp === true) {
        // @ts-ignore
        window.nativeAppCallback(resp.data.token);
        return;
      }
      
      localStorage.setItem("token", searchParams.get("token") || "");
      window.location.reload();
    }
  }, [searchParams]);

  return (
    <AuthLayout>
      <Head
        title="Log In | Short Route"
        keywords="url shortener, tiny url, link shortening, short url, shortening url, shorten url, shortener url, link shortener, short link, shorten links, shorter link, shortlink, free url shortener, short route, qr code generator, qr code"
        description="Short Route is a platform for URL shortening and link management. Easily generate custom short links, branded links, and QR codes with our free link shortener service."
      />

      <form
        className="login-page"
        onSubmit={async (e) => {
          e.preventDefault();

          setErrors({});
          setLoading(true);

          try {
            const resp: AxiosResponse<{
              token: string;
              user: User;
            }> = await axios.post("/api/user/log-in", {
              email,
              password,
            });

            // @ts-ignore
            if (window.isNativeApp === true) {
              // @ts-ignore
              window.nativeAppCallback(resp.data.token);
              return;
            }

            localStorage.setItem("token", resp.data.token);
            setUser(resp.data.user);
            navigate("/r/dashboard");
          } catch (e: any) {
            setErrors(e.response.data);
          } finally {
            setLoading(false);
          }
        }}
      >
        <h1 className="auth-tiitle">Log In</h1>
        <p className="auth-slug">
          Remember to create strong, unique passwords for each of your accounts
          and consider using a password manager to securely store them in the
          future.
        </p>

        {searchParams.has("message") && (
          <Alert
            style={{ marginTop: 30 }}
            danger={searchParams.get("error") === "true"}
          >
            {searchParams.get("message")}
          </Alert>
        )}

        <Input
          style={{ marginTop: 40 }}
          type="text"
          title="Email:"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email || errors.global}
        />
        <Input
          style={{ marginTop: 10 }}
          title="Password:"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={errors.password}
        />
        <div className="flex flex-end" style={{ marginTop: 10 }}>
          <Link to="/forgot-password" className="forgot-password">
            Forgot Password
          </Link>
        </div>

        <Button
          type="submit"
          block
          loading={loading}
          style={{ flex: 1, marginTop: 20 }}
        >
          Log In
        </Button>

        <div className="text-divider" style={{ marginTop: 25 }}>
          <div className="divider"></div>
          <div className="text">Log In with</div>
          <div className="divider"></div>
        </div>
      </form>

      <div className="flex" style={{ marginTop: 25 }}>
        <button
          className="log-in-with-button"
          onClick={(e) => {
            window.location.href = `${process.env.REACT_APP_API_URL}/api/google/log-in`;
          }}
        >
          <img src="/img/google-icon.svg" alt="google" />
          Google
        </button>
        {/* <button className="log-in-with-button">
          <img src="/img/facebook-icon.svg" alt="facebook" />
          Facebook
        </button> */}
      </div>

      <div className="sign" style={{ marginTop: 20 }}>
        Don’t have an account?{" "}
        <Link className="sign__link" to="/sign-up">
          Sign up
        </Link>
      </div>
    </AuthLayout>
  );
}
