import React, { useContext, useState } from "react";

import { AuthContext } from "../context/AuthUserContext";

import { Panel } from "../components/Panel";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { axios } from "../axios";
import { AxiosResponse } from "axios";
import { User } from "../types/User";
import { InfoModal } from "../modal/InfoModal";
import { Head } from "../components/Head";

const Email: React.FC = () => {
  const { user, setUser } = useContext(AuthContext);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState(user?.email);
  const [errors, setErrors] = useState({} as { [key: string]: string });

  return (
    <Panel style={{ marginTop: 40 }}>
      <h2 className="panel-title">Your Email</h2>

      {user?.logInWithGoogle && (
        <p>
          You was registered via Google Provider. You cannot change your email.
        </p>
      )}

      <form
        onSubmit={async (e) => {
          e.preventDefault();

          setErrors({});
          setLoading(true);

          try {
            const resp: AxiosResponse<User> = await axios.patch(
              "/api/user/change-email",
              { email }
            );

            setUser(resp.data);
            setModal(true);
          } catch (e: any) {
            setErrors(e.response.data);
          } finally {
            setLoading(false);
          }
        }}
      >
        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email || errors.global}
          disabled={user?.logInWithGoogle}
        />

        <Button
          type="submit"
          disabled={user?.logInWithGoogle}
          loading={loading}
          style={{ marginTop: 20 }}
        >
          Change Email
        </Button>
      </form>

      <InfoModal
        title="Email"
        description="Email was changed successfully."
        open={modal}
        onHide={() => setModal(false)}
      />
    </Panel>
  );
};

const Password: React.FC = () => {
  const { user } = useContext(AuthContext);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({} as { [key: string]: string });

  return (
    <Panel style={{ marginTop: 20 }}>
      <Head title="Account | Short-Route" />
      <h2 className="panel-title">Change Your Password</h2>
      {user?.logInWithGoogle && (
        <p>
          You was registered via Google Provider. You cannot change your
          password.
        </p>
      )}

      <form
        onSubmit={async (e) => {
          e.preventDefault();

          setErrors({});
          setLoading(true);

          if (newPassword !== confirmPassword) {
            setErrors({
              confirmPassword: "Password is not the same",
            });
            setLoading(false);
            return;
          }

          try {
            await axios.patch("/api/user/change-password", {
              oldPassword,
              newPassword,
            });

            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setModal(true);
          } catch (e: any) {
            setErrors(e.response.data);
          } finally {
            setLoading(false);
          }
        }}
      >
        <Input
          placeholder="Current Password:"
          value={oldPassword}
          type="password"
          onChange={(e) => setOldPassword(e.target.value)}
          error={errors.oldPassword || errors.global}
          disabled={user?.logInWithGoogle}
        />

        <Input
          placeholder="New Password:"
          value={newPassword}
          type="password"
          disabled={user?.logInWithGoogle}
          onChange={(e) => setNewPassword(e.target.value)}
          error={errors.newPassword}
          style={{ marginTop: 10 }}
        />

        <Input
          placeholder="Verify Password:"
          value={confirmPassword}
          type="password"
          disabled={user?.logInWithGoogle}
          onChange={(e) => setConfirmPassword(e.target.value)}
          style={{ marginTop: 10 }}
          error={errors.confirmPassword}
        />

        <Button
          type="submit"
          disabled={user?.logInWithGoogle}
          style={{ marginTop: 20 }}
          loading={loading}
        >
          Change Password
        </Button>
      </form>

      <InfoModal
        title="Password"
        description="Password was changed successfully."
        open={modal}
        onHide={() => setModal(false)}
      />
    </Panel>
  );
};

export const EditAccountPage: React.FC = () => {
  return (
    <>
      <h1 className="page-title">Account Settings</h1>

      <Email />
      <Password />
    </>
  );
};
