import { formatNumber } from "../utils/formatNumber";
import "./Graphic.scss";

import React, { useEffect, useRef, useState } from "react";

interface Props {
  values: number[];
  labels: string[];
}

function formatValue(val: number) {
    if (val > 1000) {
        return formatNumber(val);
    }

    const whole = + val.toFixed(0);
    const pres = + val.toFixed(2) - whole;
    if (pres === 0) {
        return val.toFixed(0);
    }

    return val.toFixed(2);
}

export const Graphic: React.FC<Props> = ({ labels, values }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [max, setMax] = useState(0);

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      // @ts-ignore
      setWidth(containerRef.current.getBoundingClientRect().width);
      // @ts-ignore
      setHeight(containerRef.current.getBoundingClientRect().height);
    }
  }, [containerRef]);

  useEffect(() => {
    let max = 0;
    values.forEach((val) => {
      max = Math.max(max, val);
    });

    setMax(max);
  }, [values]);

  const lineStep = height / 5;
  const valueStep = max / 4;
  const padding = (height * 0.2) / 2;
  const labelStep = (width - 2 * padding) / labels.length;
  const maxBarHeight = height - 2 * padding;

  
  return (
    <div className="graphic" ref={containerRef}>
      {labels.map((_, i) => {
        return (
          <div
            className="bar"
            style={{
              left: padding + labelStep * i + 50,
              bottom: padding,
              width: 20,
              height: max > 0 ? Math.max(10, maxBarHeight * (values[i] / max)) : 10,
            }}
          ></div>
        );
      })}

      {labels.map((label, i) => {
        return (
          <div
            className="x-labels-item"
            style={{
              left: padding + labelStep * i + 40,
            }}
          >
            {label}
          </div>
        );
      })}

      {[0, 1, 2, 3, 4].map((i) => {
        return (
          <div
            className="y-values-item"
            style={{
              bottom: padding + i * lineStep + 10,
            }}
          >
            {formatValue(valueStep * i)}
          </div>
        );
      })}

      {[0, 1, 2, 3, 4].map((i) => {
        return (
          <div
            className="hline"
            style={{
              bottom: padding + i * lineStep,
            }}
          />
        );
      })}
    </div>
  );
};
