import "./AuthLayout.scss";

import React from "react";
import { useNavigate } from "react-router-dom";

export function AuthLayout({ children }: { children: React.ReactNode }) {

  const navigate = useNavigate();

  return (
    <div className="auth-layout">
      <div className="brand" role="button" onClick={() => navigate("/")}>
        <img src="/img/brand-color-logo.svg" width={40} height={40} alt="Logo"/>
        <h1>Short-Route</h1>
      </div>
      <div className="content-container">
      <div className="content">{children}</div>
      </div>
    </div>
  );
}
