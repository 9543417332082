import "./Input.scss";

import React from "react";

interface Props {
  error?: string;
  text?: string;
  title?: string;
}

export const Input: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & Props
> = ({ error, text, style, title, ...props }) => {
  return (
    <div className="input" style={style}>
      {title && <span className="input__title">{title}</span>}

      <input className="input__control" {...props} />
      {text && <span>{text}</span>}

      {error && <span className="input__error">{error}</span>}
    </div>
  );
};
