import "./LogInPage.scss";

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { axios } from "../axios";

import { AuthLayout } from "../layout/AuthLayout";
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import { Head } from "../components/Head";

export function RegistrationPage() {
  const navigate = useNavigate();

  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({} as { [key: string]: string });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // @ts-ignore
      if (window.grecaptcha?.render) {
        // @ts-ignore
        window.grecaptcha.render("g-recaptcha", {
          sitekey: process.env.REACT_APP_RECAPTCHA_KEY,
          callback: setReCaptchaToken,
        });
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <AuthLayout>
      <Head
        title="Registration | Short Route"
        keywords="url shortener, tiny url, link shortening, short url, shortening url, shorten url, shortener url, link shortener, short link, shorten links, shorter link, shortlink, free url shortener, short route, qr code generator, qr code"
        description="Short Route is a platform for URL shortening and link management. Easily generate custom short links, branded links, and QR codes with our free link shortener service."
      />

      <form
        className="login-page"
        onSubmit={async (e) => {
          e.preventDefault();

          setErrors({});

          if (password !== confirmPassword) {
            setErrors({
              confirmPassword: "Password invalid",
            });
            return;
          }

          setLoading(true);

          try {
            await axios.post("/api/user", {
              firstName,
              lastName,
              email,
              password,
              reCaptchaToken,
            });

            navigate("/?message=Your account is ready. Try to log in.");
          } catch (e: any) {
            setErrors(e.response.data);
          } finally {
            setLoading(false);
          }
        }}
      >
        <h1 className="auth-tiitle">Sign Up</h1>
        <p className="auth-slug">
          Remember to create strong, unique passwords for each of your accounts
          and consider using a password manager to securely store them in the
          future.
        </p>

        <Input
          style={{ marginTop: 40 }}
          type="text"
          placeholder="First Name"
          title="First Name:"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          error={errors.firstName}
        />

        <Input
          style={{ marginTop: 10 }}
          type="text"
          title="Last Name:"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          error={errors.lastName}
        />

        <Input
          style={{ marginTop: 40 }}
          type="text"
          title="Email:"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email || errors.global}
        />

        <Input
          style={{ marginTop: 10 }}
          type="password"
          title="Password:"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={errors.password}
        />

        <Input
          style={{ marginTop: 10 }}
          type="password"
          title="Verify Password:"
          placeholder="Verify Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={errors.confirmPassword}
        />

        <div id="g-recaptcha" style={{ marginTop: 20 }}></div>
        <span style={{ color: "red" }}>
          {errors.reCaptchaToken && "Click on reCaptcha"}
        </span>

        <Button
          type="submit"
          block
          loading={loading}
          style={{ flex: 1, marginTop: 20 }}
        >
          Sign Up
        </Button>

        <div className="text-divider" style={{ marginTop: 25 }}>
          <div className="divider"></div>
          <div className="text">Sign Up with</div>
          <div className="divider"></div>
        </div>
      </form>

      <div className="flex" style={{ marginTop: 25 }}>
        <button
          className="log-in-with-button"
          onClick={(e) => {
            window.location.href = `${process.env.REACT_APP_API_URL}/api/google/log-in`;
          }}
        >
          <img src="/img/google-icon.svg" alt="google icon" />
          Google
        </button>
        {/* <button className="log-in-with-button">
          <img src="/img/facebook-icon.svg" alt="facebook icon"/>
          Facebook
        </button> */}
      </div>

      <div className="sign" style={{ marginTop: 20 }}>
        Have an account?{" "}
        <Link className="sign__link" to="/">
          Sign In
        </Link>
      </div>
    </AuthLayout>
  );
}
