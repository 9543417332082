import "./Stat.scss";
import React from "react";

interface Props {
  title: string;
  value: React.ReactNode;
  green?: boolean;
}

export const Stat: React.FC<Props> = ({ title, value, green }) => {
  return (
    <div className={"stat " + (green ? " green" : "")}>
      <span className="stat__title">{title}</span>{" "}
      <span className="stat__value">{value}</span>
    </div>
  );
};
