import "./WithCalendar.scss";

import React, { PropsWithChildren, useState } from "react";
import Calendar from "react-calendar";

type ValuePiece = Date | null;

export type Value = ValuePiece | [ValuePiece, ValuePiece];

interface Props {
  onChange: (val: Value) => void;
  value: Value;
}

export const WithCalendar: React.FC<PropsWithChildren & Props> = ({
  children,
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="with-calendar">
      <div className="with-calendar__content" onClick={() => setOpen(!open)}>
        {children}
      </div>
      {open && (
        <Calendar
          onChange={(val) => {
            onChange(val);
            setOpen(false);
          }}
          value={value}
          className="with-calendar__calendar"
        />
      )}
    </div>
  );
};
