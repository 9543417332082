import axios0 from "axios";

export const axios = axios0.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.set("json-web-token", token);
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      [401, 403].indexOf(error.response.status) > -1 &&
      window.location.href !== "/"
    ) {
      window.localStorage.removeItem("token");
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);
