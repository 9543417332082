import "./Checkbox.scss";

import React from "react";

interface Props {
  error?: string;
  text: string;
  title?: string;
}

export const Checkbox: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & Props
> = ({ error, text, style, title, id, ...props }) => {
  return (
    <div className="checkbox" style={style}>
      {title && <span className="input__title">{title}</span>}

      <input className="checkbox__control" id={id} type="checkbox" {...props} />
      {text && <label htmlFor={id}>{text}</label>}

      {error && <span className="input__error">{error}</span>}
    </div>
  );
};
