import React from "react";

import { ModalProps } from "../components/Modal";

import { Modal } from "../components/Modal";
import { Button } from "../components/Button";

interface Props extends ModalProps {
  title: string;
  description: string;
  onYes: () => void;
}

export const ConfirmationModal: React.FC<Props> = ({
  open,
  title,
  description,
  onYes,
  onHide,
}) => {
  return (
    <Modal open={open} onHide={onHide}>
      <h2 className="modal__title">{title}</h2>
      <p className="modal__description">{description}</p>

      <Button block onClick={async () => await onYes()}>
        Remove
      </Button>
      <Button
        block
        style={{ marginTop: 10 }}
        onClick={() => onHide && onHide()}
      >
        Cancel
      </Button>
    </Modal>
  );
};
