import React, { useState } from "react";
import { Panel } from "../Panel";
import { Input } from "../Input";
import { Button } from "../Button";
import { axios } from "../../axios";
import { InfoModal } from "../../modal/InfoModal";

interface Props {
  onUpdate: () => void;
}

export const ShortenUrlForm: React.FC<Props> = ({ onUpdate }) => {
  const [link, setLink] = useState("");
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as { [key: string]: string });

  return (
    <Panel style={{ marginTop: 30 }}>
      <h2 className="panel__title">Shorten URL:</h2>
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          setLoading(true);

          try {
            await axios.post("/api/link", { link });
            setLink("");
            setModal(true);
            onUpdate();
          } catch (e: any) {
            setErrors(e.response.data);
          } finally {
            setLoading(false);
          }
        }}
      >
        <Input
          title="Your link:"
          placeholder="Your link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          error={errors.link || errors.global}
        />
        <Button type="submit" loading={loading} style={{ marginTop: 10 }}>
          Shorten
        </Button>
      </form>

      <InfoModal
        title="Success"
        description="Your link is ready."
        open={modal === true}
        onHide={() => setModal(false)}
      />
    </Panel>
  );
};
