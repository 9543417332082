import "./Dashboard.scss";

import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";

import { Pagination } from "../types/Pagination";
import { Link } from "../types/Link";
import { Statistics } from "../types/Statistics";

import { axios } from "../axios";

import { ShortLink } from "../components/ShortLink";
import { Chip } from "../components/Chip";
import { Panel } from "../components/Panel";
import { formatNumber } from "../utils/formatNumber";
import { PageLoading } from "../components/PageLoading";
import { Empty } from "../components/Empty";
import { Graphic } from "../components/Graphic";
import { ShortenUrlForm } from "../components/dashboard/ShortenUrlForm";
import { Head } from "../components/Head";

export function DashboardPage() {
  const [links, setLinks] = useState(null as Pagination<Link> | null);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [statistics, setStatistics] = useState({
    totalLinks: 0,
    totalClicks: 0,
    linkAddedThisMonth: 0,
    statByDate: [],
  } as Statistics);

  async function stat() {
    const resp: AxiosResponse<Statistics> = await axios.get("/api/stat");
    setStatistics(resp.data);
  }

  async function refresh() {
    setLoading(true);
    try {
      const resp: AxiosResponse<Pagination<Link>> = await axios.get(
        "/api/link",
        {
          params: {
            sort: "id,desc",
            size: 5,
          },
        }
      );
      setLinks(resp.data);
    } finally {
      setLoading(false);
      setFirstLoading(false);
    }
  }

  useEffect(() => {
    refresh();
    stat();
  }, []);

  return (
    <>
      <Head title="Dashboard | Short-Route" />
      {firstLoading && <PageLoading />}

      {!firstLoading && (
        <>
          <h1 className="page-title">Dashboard</h1>
          <Chip>
            All URLs: <b>{formatNumber(statistics.totalLinks)}</b>
          </Chip>
          <Chip style={{ marginLeft: 15 }}>
            Total Clicks: <b>{formatNumber(statistics.totalClicks)}</b>
          </Chip>
          <Chip style={{ marginLeft: 15 }}>
            Links added this month:{" "}
            <b>{formatNumber(statistics.linkAddedThisMonth)}</b>
          </Chip>

          <Panel style={{ marginTop: 30 }}>
            <Graphic
              labels={statistics.statByDate.map((item) => item.date)}
              values={statistics.statByDate.map((item) => item.count)}
            />
          </Panel>

          <ShortenUrlForm onUpdate={refresh} />

          {links?.content.length !== 0 && (
            <h2 className="second-page-title">Recent Links</h2>
          )}

          {!loading && links?.content.length === 0 && (
            <Empty withPadding={true} />
          )}

          {(links?.content || []).map((link) => {
            return (
              <Panel
                key={link.id}
                style={{ marginBottom: 20, paddingBottom: 0 }}
              >
                <ShortLink onUpdate={() => refresh()} link={link} />
              </Panel>
            );
          })}
        </>
      )}
    </>
  );
}
